import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";

import * as Actions from "../constants";
import { 
  CheckIfRegisteredUserInput, 
  RegisterUserInput,
  UpdateUserInput,
  LoginUserInput,
} from "../../lib/validations/user.schema";

import { VerifyEmailInput } from "../../types/cognitouser";
import { handleAxiosError } from "../../utils/functions";
import { ResetPasswordActionInput } from "src/types/user";

export const checkIfVerifiedEmail:any = (data:CheckIfRegisteredUserInput) => async (dispatch: Dispatch) => {
  dispatch({ 
    type: Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_REQUEST
  });
  return axios.post(`${process.env.REACT_APP_SERVER_API}/witness/checkemailverifiedstatus`, data)
    .then(response => {
      dispatch({ 
        type: Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_SUCCESS,
        payload: {
          ...data,
          UserStatus: response.data.result
        }
      });
      return response.data;
    })
    .catch((error:AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_CHCKIFEMAILVERIFIED_FAILURE,
        error
      });
      handleAxiosError(error);
      return error.response.data;
    });
};

// Verify user email action
export const verifyEmail: any = (data: VerifyEmailInput) => async (dispatch: Dispatch) => {

  dispatch({ type: Actions.COGNITOUSER_VERIFY_EMAIL_REQUEST });
  
  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/verifyemail`, data)
    .then(response => {
      dispatch({ type: Actions.COGNITOUSER_VERIFY_EMAIL_SUCCESS });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_VERIFY_EMAIL_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Resend a verification code if didn't receive
export const resendCode: any = (email: string) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.RESEND_EMAIL_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/resendverificationcode`, { email })
    .then(response => {
      dispatch({ type: Actions.RESEND_EMAIL_SUCCESS });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({ type: Actions.RESEND_EMAIL_FAILURE });
      handleAxiosError(error);
    });
};

// Verify user email action
export const checkConfirmationStatus: any = (data: VerifyEmailInput) => async (dispatch: Dispatch) => {

  dispatch({ type: Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/checkconfirmationstatus`, data)
    .then(response => {
      dispatch({ type: Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_SUCCESS, payload:response.data });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_GETCONFIRMATIONSTATUS_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Register action
export const registerUser: any = (data: RegisterUserInput) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_REGISTER_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/register`, data)
    .then(response => {
      // console.log("register response:", response);
      if (response.data.success) {
        localStorage.setItem("access-token", response.data.token);
        // console.log("register", response.data);
        //dispatch(loginWithToken(false));
        dispatch({ type: Actions.COGNITOUSER_REGISTER_SUCCESS });
        return response.data;
      } else {
        dispatch({
          type: Actions.COGNITOUSER_REGISTER_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_REGISTER_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Register Witness action
export const registerWitnessUser: any = (data: RegisterUserInput) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_REGISTER_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/witnessuser/register`, data)
    .then(response => {
      // console.log("register response:", response);
      if (response.data.success) {
        localStorage.setItem("access-token", response.data.token);
        // console.log("register", response.data);
        //dispatch(loginWithToken(false));
        dispatch({ type: Actions.COGNITOUSER_REGISTER_SUCCESS });
        return response.data;
      } else {
        dispatch({
          type: Actions.COGNITOUSER_REGISTER_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_REGISTER_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Update action
export const updateUser: any = (data: UpdateUserInput) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_UPDATEINFO_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/attributes`, data)
    .then(response => {
      if (response.data.success) {
        dispatch({ type: Actions.COGNITOUSER_UPDATEINFO_SUCCESS, payload:response.data });
        return response.data;
      } else {
        dispatch({
          type: Actions.COGNITOUSER_UPDATEINFO_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_UPDATEINFO_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Login action
export const loginUser: any = (data: LoginUserInput) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.COGNITOUSER_LOGIN_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/witness/login`, data)
    .then(response => {
      dispatch({ type: Actions.COGNITOUSER_LOGIN_SUCCESS });
      if (response.data.success) {
        localStorage.setItem("access-token", response.data.token);
        dispatch(loginWithToken(true));
      }
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.COGNITOUSER_LOGIN_FAILURE,
        error
      });
      handleAxiosError(error);
      return error.response.data;
    });
};

// Login with JWT token action
export const loginWithToken: any = (refresh: boolean = true) => async (dispatch: Dispatch) => {
  if (refresh) {
    dispatch({ type: Actions.COGNITOUSER_LOGIN_WITH_TOKEN_REQUEST });
  }

  const accessToken: string | null = localStorage.getItem("access-token");
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return axios.get(`${process.env.REACT_APP_SERVER_API}/witness/access-token`)
    .then(async response => {
      dispatch({
        type: Actions.COGNITOUSER_LOGIN_WITH_TOKEN_SUCCESS,
        payload: response.data
      });
      localStorage.setItem("currentuser", response.data);
      return response.data;
    })
    .catch(error => {
      dispatch({
        type: Actions.COGNITOUSER_LOGIN_WITH_TOKEN_FAILURE,
        error
      });

      localStorage.removeItem("access-token");
      localStorage.removeItem("currentuser");
    });
};

// Logout user action
export const logoutUser: any = () => {
  localStorage.removeItem("access-token");
  localStorage.removeItem("currentuser");
  axios.defaults.headers.common.Authorization = "Bearer ";
  return { type: Actions.COGNITOUSER_LOGOUT };
};

// Confirm email to send a code if forgot password
export const confirmEmail: any = (email: string) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.FORGOT_PASSWORD_EMAIL_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/witness/forgotpassword`, { email })
    .then(response => {
      dispatch({ type: Actions.FORGOT_PASSWORD_EMAIL_SUCCESS });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({ type: Actions.FORGOT_PASSWORD_EMAIL_FAILURE });
      handleAxiosError(error);
      return error.response.data;
    });
};

// Verify code sent to the email once the email is confirmed
export const resetPassword: any = (data: ResetPasswordActionInput) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.RESET_PASSWORD_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/cognitouser/resetpassword`, data)
    .then(response => {
      dispatch({ type: Actions.RESET_PASSWORD_SUCCESS });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({ type: Actions.RESET_PASSWORD_FAILURE });
      handleAxiosError(error);
      return error.response.data;
    });
};
